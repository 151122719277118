<template>
  <div>
    <StoreSwitcher v-if="isAdmin" />
    <ProductsReportFilter v-model="filter" @filter="loadPayments" />
    <ProductsReportList
      hide-warnings
      :payments="payments"
      @refresh="loadPayments"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import withAsyncAction from '@/mixins/withAsyncAction'
import services from '@/services'
import ProductsReportFilter from './ProductsReportFilter'
import ProductsReportList from '@/components/Reports/Products/ProductsReportList'
import { formatDate } from '@/helpers/date'
import StoreSwitcher from '@/components/Stores/StoreSwitcher'

export default {
  name: 'ProductsReport',
  components: {
    ProductsReportFilter,
    ProductsReportList,
    StoreSwitcher
  },
  mixins: [
    withAsyncAction({
      key: 'payments',
      initialState: [],
      fetcher: {
        methodName: 'fetchProductPaymentsReport',
        handler: services.reports.fetchProductPaymentsReport
      }
    })
  ],
  data() {
    return {
      filter: {
        fromDate: formatDate(new Date()),
        toDate: formatDate(new Date())
      }
    }
  },
  computed: {
    ...mapGetters('store', ['currentStoreId', 'isAdmin'])
  },
  created() {
    this.loadPayments()
  },
  methods: {
    loadPayments() {
      const storeId = this.currentStoreId
      const { fromDate, toDate } = this.filter
      this.fetchProductPaymentsReport({ storeId, fromDate, toDate })
    }
  }
}
</script>
