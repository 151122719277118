<template>
  <Layout>
    <b-breadcrumb :items="breadcrumbs" />
    <ProductsReport />
  </Layout>
</template>

<script>
import Layout from '@/views/Layout'
import ProductsReport from '@/components/Reports/Products/ProductsReport'

export default {
  name: 'ReportsProducts',
  components: {
    Layout,
    ProductsReport
  },
  computed: {
    breadcrumbs: () => [
      {
        text: 'Início',
        to: { name: 'HomeIndex' }
      },
      {
        text: 'Relatórios',
        to: { name: 'ReportsIndex' }
      },
      {
        text: 'Produtos',
        to: { name: 'ReportProductsPayment' },
        active: true
      }
    ]
  }
}
</script>
