<template>
  <div>
    <StateRenderer v-bind="payments">
      <b-alert
        v-if="payments.data.length === 0"
        show
        variant="secondary"
        class="text-center"
      >
        Nenhum produto vendido neste período
      </b-alert>
      <template v-else>
        <h4>
          <b> Total: {{ calculateTotal | formatCurrency }} </b>
        </h4>
        <b-table
          ref="paymentsList"
          :fields="fields"
          :items="payments.data"
          striped
          hover
          :busy="payments.loading"
          responsive
        >
          <template #table-colgroup="scope">
            <col v-for="field in scope.fields" :key="field.key" />
          </template>
          <template #cell(createdAt)="{ item }">
            <span class="d-block">
              {{ item.createdAt | formatStandardDateTime }}
            </span>
          </template>
          <template #cell(cash)="{ item }">
            <span class="d-block">
              {{ getProductAmount(item, 'cash') | formatCurrency }}
            </span>
          </template>
          <template #cell(debitCard)="{ item }">
            <span class="d-block">
              {{ getProductAmount(item, 'debitCard') | formatCurrency }}
            </span>
          </template>
          <template #cell(creditCard)="{ item }">
            <span class="d-block">
              {{ getProductAmount(item, 'creditCard') | formatCurrency }}
            </span>
          </template>
          <template #cell(pix)="{ item }">
            <span class="d-block">
              {{ getProductAmount(item, 'pix') | formatCurrency }}
            </span>
          </template>
        </b-table>
      </template>
    </StateRenderer>
  </div>
</template>

<script>
import { reduce, get } from 'lodash/fp'
import { formatCurrency } from '@/helpers/numbers'
import { formatStandardDateTime } from '@/helpers/date'

import StateRenderer from '@/components/StateRenderer'

export default {
  name: 'ProductsReportList',
  filters: {
    formatCurrency,
    formatStandardDateTime
  },
  components: {
    StateRenderer
  },
  props: {
    payments: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      filter: {
        storeId: null,
        date: null
      }
    }
  },
  computed: {
    fields: () => [
      {
        key: 'createdAt',
        label: 'Data'
      },
      {
        key: 'product.name',
        label: 'Produto'
      },
      {
        key: 'cash',
        label: 'Total Dinheiro'
      },
      {
        key: 'creditCard',
        label: 'Total Crédito'
      },
      {
        key: 'debitCard',
        label: 'Total Débito'
      },
      {
        key: 'pix',
        label: 'Total Pix'
      }
    ],
    calculateTotal() {
      return reduce(
        (result, current) => (result += current.amount),
        0,
        this.payments.data
      )
    }
  },
  methods: {
    getProductAmount(payment, type) {
      if (payment.type !== type) return 0
      return get('amount', payment)
    }
  }
}
</script>
